import React, { useState } from "react";
import { MenuOverlayOptions } from "../../../enums/menu-overlay-options";
import styles from "./BlogHeader.module.scss";
import MenuOverlay from "../menu-overlay";
import AFGCHeader from "../afgc-header";
import cx from "classnames";

interface Props {
  children: React.ReactNode;
  selected?: MenuOverlayOptions;
  className?: string;
}

const BlogHeader: React.FC<Props> = (props: Props) => {
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  return (
    <header className={styles.header}>
      <div className={cx("column", styles.content, props.className ?? "")}>
        <AFGCHeader
          iconClick={() => setIsPopUpOpen(true)}
          useHeader={false}
          contentClassName={styles.original}
        />
        {props.children}
        <MenuOverlay
          closePopUp={() => setIsPopUpOpen(false)}
          isOpen={isPopUpOpen}
          selected={props.selected}
        />
      </div>
    </header>
  );
};

export default BlogHeader;
