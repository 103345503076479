import React from "react";
import styles from "./FooterList.module.scss";
import { Link } from "gatsby";
import ChevronUpIcon from "../../../../images/icons/chevron-up.inline.svg";
import ChevronDownIcon from "../../../../images/icons/chevron-down.inline.svg";
import { SlideDown } from "react-slidedown";
import cx from "classnames";
import SVGContainer from "../../../commons/svg-container/SVGContainer";

interface Props {
  heading: string;
  textList: string[];
  linkArray: string[];
  opened: boolean;
  handleClick: Function;
  showArrow?: boolean;
  dataTestE2E: string[];
}

const FooterList: React.FC<Props> = (props: Props) => {
  return (
    <ul className={styles.footerList}>
      <li className={cx("column", styles.footerList__heading)}>
        <div className={styles.row} onClick={() => props.handleClick()}>
          <p>{props.heading}</p>
          <SVGContainer
            className={cx(
              styles.arrow,
              props.showArrow && styles.showArrow,
              props.opened ? styles.upArrow : styles.downArrow
            )}
          >
            {props.opened ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </SVGContainer>
        </div>
        <div className={styles.footerList__divider} />
      </li>
      <SlideDown>
        {props.opened
          ? props.textList.map((el, index) => (
              <li key={index} className={styles.footerList__children}>
                {props.linkArray[index].startsWith("/") && (
                  <Link
                    to={props.linkArray[index]}
                    className="link"
                    data-test-e2e={props.dataTestE2E[index]}
                  >
                    {el}
                  </Link>
                )}

                {!props.linkArray[index].startsWith("/") && (
                  <a
                    href={props.linkArray[index]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link"
                    data-test-e2e={props.dataTestE2E[index]}
                  >
                    {el}
                  </a>
                )}
              </li>
            ))
          : null}
      </SlideDown>
      {props.opened && <li className={styles.openedGap} />}
    </ul>
  );
};

export default FooterList;
