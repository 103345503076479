import React, { useState } from "react";
import AFGCLogo from "../../commons/afgc-logo/AFGCLogo";
import LogoIcon from "../../../images/icons/v2/logo.inline.svg";
import styles from "./footer.module.scss";
import FacebookIcon from "../../../images/icons/facebook.inline.svg";
import InstagramIcon from "../../../images/icons/instagram.inline.svg";
import PinterestIcon from "../../../images/icons/pinterest.inline.svg";
import { IconSizes } from "../../../enums/icon-sizes";
import FooterList from "./footer-list/FooterList";
import { RouteLinks } from "../../../utils/route-links";
import { smallScreenQuery } from "../../../utils/utils";
import { useMediaQuery } from "react-responsive";
import cx from "classnames";
import SVGContainer from "../../commons/svg-container/SVGContainer";
import AFGCAnchor from "../../commons/afgc-anchor/AFGCAnchor";
import { SocialLinks } from "../../../utils/social-links";
import { graphql, useStaticQuery } from "gatsby";
import { GqlListResponse } from "../../../models/gql-list-response.model";
import { Portfolio } from "../../../models/portfolio.model";
import { Package } from "../../../models/package.model";
import { getPackagesLink } from "../../../utils/link.helper";
import AsteriskIcon from "../../../images/icons/asterisk-transparent.svg";
import { Slugs } from "../../../enums/slugs.enum";

interface Props {
  className?: string;
}

const query = graphql`
  {
    portfolios: allMarkdownRemark(
      filter: {
        fields: { category: { eq: "portfolios" } }
        frontmatter: { link_to_home_page: { eq: true } }
      }
      sort: { order: ASC, fields: fields___fileName___relativePath }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            packages {
              location
              location_slug
            }
          }
        }
      }
    }
    maui: allMarkdownRemark(
      filter: {
        fields: { category: { eq: "portfolios" } }
        frontmatter: {
          packages: { elemMatch: { location_slug: { eq: "maui" } } }
        }
      }
      sort: { order: ASC, fields: fields___fileName___relativePath }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
          }
        }
      }
    }
    kauai: allMarkdownRemark(
      filter: {
        fields: { category: { eq: "portfolios" } }
        frontmatter: {
          packages: { elemMatch: { location_slug: { eq: "kauai" } } }
        }
      }
      sort: { order: ASC, fields: fields___fileName___relativePath }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
          }
        }
      }
    }
    sfBayArea: allMarkdownRemark(
      filter: {
        fields: { category: { eq: "portfolios" } }
        frontmatter: {
          packages: { elemMatch: { location_slug: { eq: "sf-bay-area" } } }
        }
      }
      sort: { order: ASC, fields: fields___fileName___relativePath }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
          }
        }
      }
    }

    site {
      siteMetadata {
        app_version
      }
    }
  }
`;

const Footer: React.FC<Props> = (props: Props) => {
  // Footer list opening
  const [openedIndex, setOpenedIndex] = useState(0);
  const [packageOneOpened, setPackageOneOpened] = useState<boolean>(false);
  const [packageTwoOpened, setPackageTwoOpened] = useState<boolean>(false);
  const [packageThreeOpened, setPackageThreeOpened] = useState<boolean>(false);

  const matches = useMediaQuery({ query: smallScreenQuery });

  const queryResult = useStaticQuery(query);

  const portfoliosRaw: GqlListResponse<Portfolio> = queryResult.portfolios;
  const portfolios = portfoliosRaw.edges;

  const mauiPackagesRaw: GqlListResponse<Portfolio> = queryResult.maui;
  const mauiPackages = mauiPackagesRaw.edges;

  const sfBayPackagesRaw: GqlListResponse<Portfolio> = queryResult.sfBayArea;
  const sfBayPackages = sfBayPackagesRaw.edges;

  const kauaiPackagesRaw: GqlListResponse<Portfolio> = queryResult.kauai;
  const kauaiPackages = kauaiPackagesRaw.edges;

  const appVersion = queryResult.site.siteMetadata.app_version;

  const getFooterPackagesLinks = () => {
    const links = portfolios.map(({ node: { frontmatter } }) => {
      const packages = frontmatter.packages || [];

      const firstPackage = packages[0];
      const hawaiiPackage = packages.find(
        (p: Package) => p.location_slug === `${Slugs.LOCATION_HAWAII}`
      );
      let featuredPackage = hawaiiPackage || firstPackage;

      if (
        frontmatter.slug === `${Slugs.PORTFOLIO_NEWBORN}` ||
        frontmatter.slug === `${Slugs.PORTFOLIO_MATERNITY}`
      ) {
        const kauaiPackage = packages.find(
          (p: Package) => p.location_slug === `${Slugs.LOCATION_KAUAI}`
        );
        featuredPackage = kauaiPackage || firstPackage;
      }

      return getPackagesLink(frontmatter.slug, featuredPackage.location_slug);
    });

    return links;
  };

  const handlePackages = () => {
    return (
      <FooterList
        heading="Packages"
        textList={portfolios.map(
          ({ node: { frontmatter } }) => frontmatter.title
        )}
        linkArray={getFooterPackagesLinks()}
        opened={matches ? openedIndex === 1 : true}
        handleClick={() => {
          if (matches) {
            setOpenedIndex(1);
          }
        }}
        dataTestE2E={[
          "packages-wedding",
          "packages-engagement",
          "packages-newborn",
          "packages-maternity",
          "packages-portraits",
          "packages-family",
          "packages-food",
        ]}
      />
    );
  };

  return (
    <footer className={cx("column", styles.blackFooter, props.className ?? "")}>
      <div className={styles.asterisk}>
        <img src={AsteriskIcon} alt="Asterisk Icon" />
      </div>
      <div className={styles.blackFooter__content}>
        <section className={styles.leftContainer}>
          <div className={styles.leftContainer__content}>
            <AFGCLogo className={styles.leftContainer__logo}>
              <LogoIcon />
            </AFGCLogo>
            <p className={styles.blackFooter__desc}>
              All the images used in this website are the property of{" "}
              <span>A FEW GOOD CLICKS</span> and used with permission.
            </p>
            <div className={styles.blackFooter__social}>
              <AFGCAnchor
                ariaLabel="Share on Facebook"
                url={SocialLinks.facebook}
                dataTestE2E="action-facebook"
              >
                <SVGContainer iconSize={IconSizes.IC__30}>
                  <FacebookIcon />
                </SVGContainer>
              </AFGCAnchor>
              <AFGCAnchor
                ariaLabel="Share on Instagram"
                url={SocialLinks.instagram}
                dataTestE2E="action-instagram"
              >
                <SVGContainer iconSize={IconSizes.IC__30}>
                  <InstagramIcon />
                </SVGContainer>
              </AFGCAnchor>
              <AFGCAnchor
                ariaLabel="Share on Pinterest"
                url={SocialLinks.pinterest}
                dataTestE2E="action-pinterest"
              >
                <SVGContainer iconSize={IconSizes.IC__30}>
                  <PinterestIcon />
                </SVGContainer>
              </AFGCAnchor>
            </div>
          </div>
        </section>
        <section className={styles.rightContainer}>
          <div className={styles.rightContainer__row}>
            <FooterList
              heading="Navigation"
              textList={["Home", "Portfolio", "Blog", "Who am I", "Contact me"]}
              linkArray={[
                RouteLinks.index,
                RouteLinks.portfolio,
                RouteLinks.blogsAll,
                RouteLinks.whoAmI,
                RouteLinks.contact,
              ]}
              opened={matches ? openedIndex === 0 : true}
              handleClick={() => {
                if (matches) {
                  setOpenedIndex(0);
                }
              }}
              dataTestE2E={[
                "navigation-home",
                "navigation-portfolio",
                "navigation-blogs",
                "navigation-whoami",
                "navigation-contact",
              ]}
            />
            {handlePackages()}
            <FooterList
              heading="Help"
              textList={[
                "COVID-19",
                "Privacy Policy",
                "Cookie Policy",
                "Terms of use",
              ]}
              linkArray={[
                RouteLinks.covid,
                RouteLinks.privacy,
                RouteLinks.cookie,
                RouteLinks.terms,
              ]}
              opened={matches ? openedIndex === 2 : true}
              handleClick={() => {
                if (matches) {
                  setOpenedIndex(2);
                }
              }}
              dataTestE2E={[
                "help-covid",
                "help-privacy",
                "help-cookie",
                "help-terms",
              ]}
            />
          </div>
          <div
            className={cx(
              styles.rightContainer__row,
              styles.rightContainer__locations
            )}
          >
            <FooterList
              heading="SF bay area"
              textList={sfBayPackages.map(
                ({ node: { frontmatter } }) => frontmatter.title
              )}
              linkArray={sfBayPackages.map(({ node: { frontmatter } }) =>
                getPackagesLink(frontmatter.slug, Slugs.LOCATION_SF_BAY_AREA)
              )}
              opened={openedIndex === 3 || packageOneOpened}
              showArrow
              handleClick={() => {
                if (matches) {
                  setOpenedIndex(3);
                } else {
                  setPackageOneOpened(!packageOneOpened);
                }
              }}
              dataTestE2E={[
                "sf-bay-weddings",
                "sf-bay-engagement",
                "sf-bay-food",
              ]}
            />
            <FooterList
              heading="Kauai"
              textList={kauaiPackages.map(
                ({ node: { frontmatter } }) => frontmatter.title
              )}
              linkArray={kauaiPackages.map(({ node: { frontmatter } }) =>
                getPackagesLink(frontmatter.slug, Slugs.LOCATION_KAUAI)
              )}
              opened={openedIndex === 4 || packageTwoOpened}
              showArrow
              handleClick={() => {
                if (matches) {
                  setOpenedIndex(4);
                } else {
                  setPackageTwoOpened(!packageTwoOpened);
                }
              }}
              dataTestE2E={[
                "kauai-weddings",
                "kauai-engagament",
                "kauai-newborn",
                "kauai-maternity",
                "kauai-portrait",
                "kauai-family",
                "kauai-food",
              ]}
            />
            <FooterList
              heading="Maui"
              textList={mauiPackages.map(
                ({ node: { frontmatter } }) => frontmatter.title
              )}
              linkArray={mauiPackages.map(({ node: { frontmatter } }) =>
                getPackagesLink(frontmatter.slug, Slugs.LOCATION_MAUI)
              )}
              opened={openedIndex === 5 || packageThreeOpened}
              showArrow
              handleClick={() => {
                if (matches) {
                  setOpenedIndex(5);
                } else {
                  setPackageThreeOpened(!packageThreeOpened);
                }
              }}
              dataTestE2E={["maui-weddings", "maui-engagement", "maui-food"]}
            />
          </div>
        </section>
      </div>
      <p className={cx("column", styles.blackFooter__last)}>
        <span>v{appVersion}</span>
        <span>&bull;</span>
        &copy; a few good clicks 2014&nbsp;&#8211; {new Date().getFullYear()}
      </p>
    </footer>
  );
};

export default Footer;
