import React, { useState } from "react";
import FAQCard from "./faq-card/FAQCard";
import { FAQ } from "../../../models/faq.model";
import styles from "./FAQGroup.module.scss";
import { RouteLinks } from "../../../utils/route-links";
import { Link } from "gatsby";

interface Props {
  faqs: FAQ[];
}

const FAQGroup: React.FC<Props> = (props: Props) => {
  const [openedIndex, setOpenedIndex] = useState(0);

  // if (props.faqs.length === 0) {
  //   return (
  //     <div className={styles.FAQGroup__empty}>
  //       <h4 className="d6">
  //         Check out the general FAQs&nbsp;
  //         <span>
  //           <Link to={RouteLinks.faqs} className="link">
  //             here
  //           </Link>
  //         </span>
  //       </h4>
  //     </div>
  //   );
  // }

  return (
    <>
      {props.faqs.map((el, index) => {
        return (
          <FAQCard
            key={index}
            ques={el.question}
            ans={el.answer}
            opened={openedIndex == index}
            handleClick={() => setOpenedIndex(index)}
          />
        );
      })}
    </>
  );
};

export default FAQGroup;
