import React from "react";
import V2Entry from "../v2-entry";
import Footer from "../footer/Footer";

interface Props {
  children?: React.ReactNode;
}

const BlogLayout: React.FC<Props> = (props: Props) => {
  return (
    <V2Entry>
      {props.children}
      <Footer />
    </V2Entry>
  );
};

export default BlogLayout;
