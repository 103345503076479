import React from "react";
import styles from "./FAQCard.module.scss";
import PlusIcon from "../../../../images/icons/plus.inline.svg";
import MinusIcon from "../../../../images/icons/minus.inline.svg";
import cx from "classnames";
import { IconSizes } from "../../../../enums/icon-sizes";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
import SVGContainer from "../../svg-container/SVGContainer";

interface Props {
  ques: string;
  ans: string;
  opened: boolean;
  handleClick: () => void;
}

const FAQCard: React.FC<Props> = (props: Props) => {
  return (
    <article onClick={props.handleClick} className={cx("open", styles.FAQCard)}>
      <div
        className={cx(
          styles.ques,
          props.opened ? styles.ques__open : styles.ques__closed
        )}
      >
        <p>{props.ques}</p>
        <SVGContainer iconSize={IconSizes.IC__packagesFAQ}>
          {props.opened ? <MinusIcon /> : <PlusIcon />}
        </SVGContainer>
      </div>
      <SlideDown>
        {props.opened ? <p className={styles.ans}>{props.ans}</p> : null}
      </SlideDown>
    </article>
  );
};

export default FAQCard;
