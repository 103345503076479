import React from "react";

import styles from "./footer.module.scss";
import LogoIcon from "../../../images/icons/v1/logo.inline.svg";
import FacebookIcon from "../../../images/icons/v1/facebook.inline.svg";
import PinterestIcon from "../../../images/icons/pinterest.inline.svg";
import InstagramIcon from "../../../images/icons/instagram.inline.svg";
import cx from "classnames";
import { Link } from "gatsby";
import { RouteLinks } from "../../../utils/route-links";
import AFGCAnchor from "../../commons/afgc-anchor/AFGCAnchor";
import AFGCLogo from "../../commons/afgc-logo/AFGCLogo";
import SVGContainer from "../../commons/svg-container/SVGContainer";
import { SocialLinks } from "../../../utils/social-links";
import { IconSizes } from "../../../enums/icon-sizes";

interface Props {
  className?: string;
}

const Footer: React.FC<Props> = (props: Props) => {
  return (
    <footer className={cx("column", styles.footer, props.className ?? "")}>
      <AFGCLogo className={styles.footer__logo}>
        <LogoIcon />
      </AFGCLogo>
      <div className={cx("column", "d7", styles.footer__row)}>
        <Link to={RouteLinks.index}>Home</Link>
        <Link to={RouteLinks.portfolio}>Portfolio</Link>
        <Link to={RouteLinks.testimonial}>Testimonials</Link>
        <Link to={RouteLinks.whoAmI}>Who am I</Link>
        <Link to={RouteLinks.blogs}>Blog</Link>
        <Link to={RouteLinks.contact}>Contact Me</Link>
      </div>
      <div className={styles.footer__social}>
        <AFGCAnchor ariaLabel="Share on Instagram" url={SocialLinks.instagram}>
          <SVGContainer iconSize={IconSizes.IC__footer}>
            <InstagramIcon />
          </SVGContainer>
        </AFGCAnchor>
        <AFGCAnchor ariaLabel="Share on Pinterest" url={SocialLinks.pinterest}>
          <SVGContainer iconSize={IconSizes.IC__footer}>
            <PinterestIcon />
          </SVGContainer>
        </AFGCAnchor>
        <AFGCAnchor ariaLabel="Share on Facebook" url={SocialLinks.facebook}>
          <SVGContainer iconSize={IconSizes.IC__footer}>
            <FacebookIcon />
          </SVGContainer>
        </AFGCAnchor>
      </div>
      <p className="d10">
        All the images used in this website are the property of&nbsp;
        <span>
          <Link to={RouteLinks.index} className="link">
            a few good clicks
          </Link>
        </span>
        &nbsp;and used with permission.
      </p>
    </footer>
  );
};

export default Footer;
