import React from "react";
import V1Entry from "../v1-entry";
import Footer from "../footer/Footer";

interface Props {
  children: React.ReactNode;
}

const BlogLayout: React.FC<Props> = (props: Props) => {
  return (
    <V1Entry>
      <div className="column">
        {props.children}
        <Footer />
      </div>
    </V1Entry>
  );
};

export default BlogLayout;
