import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GqlListResponse } from "../../../models/gql-list-response.model";
import { FAQ } from "../../../models/faq.model";
import SEO from "../seo";
import cx from "classnames";
import styles from "./faqs.module.scss";
import FAQGroup from "../faq-group/FAQGroup";
import { RouteLinks } from "../../../utils/route-links";

const query = graphql`
  {
    faqs: allMarkdownRemark(
      filter: { fields: { category: { eq: "faqs" } } }
      sort: { order: ASC, fields: frontmatter___order }
    ) {
      edges {
        node {
          frontmatter {
            question
            answer
          }
        }
      }
    }

    seo: markdownRemark(
      fields: { category: { eq: "seo" }, relativePath: { eq: "seo__faqs" } }
    ) {
      frontmatter {
        metaTitle
        metaDescription
        metaKeywordList
        metaImageName
      }
    }
  }
`;

const FAQs = () => {
  const queryResult = useStaticQuery(query);

  const rawFaqs: GqlListResponse<{ frontmatter: FAQ }> = queryResult.faqs;
  const faqs = rawFaqs.edges.map(f => f.node.frontmatter);

  const {
    metaTitle,
    metaDescription,
    metaKeywordList,
    metaImageName,
  } = queryResult.seo.frontmatter;

  const richPageData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      faqs.map(faq => ({
        "@type": "Question",
        name: faq.question,
        acceptedAnswer: {
          "@type": "Answer",
          text: faq.answer,
        },
      })),
    ],
  };

  return (
    <>
      <SEO
        title={metaTitle}
        description={metaDescription}
        keywords={metaKeywordList}
        imageUrl={!!metaImageName ? `/previews/${metaImageName}` : undefined}
        pageUrl={RouteLinks.faqs}
        richData={richPageData}
      />
      <main
        className={cx("column", styles.mainContent)}
        data-test-e2e="faq-container"
      >
        <p className={styles.mainContent__desc}>
          Below, I have compiled a list of frequently asked questions for your
          convenience, but if you have any other questions, please feel free to
          contact me.
        </p>
        <FAQGroup faqs={faqs} />
      </main>
    </>
  );
};

export default FAQs;
