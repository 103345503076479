import React from "react";
import styles from "./faqs.module.scss";
import BlogLayout from "../../blog-layout/BlogLayout";
import BlogHeader from "../../blog-header/BlogHeader";
import FaqComponent from "../../../commons/faqs";

const FAQs = () => {
  return (
    <BlogLayout>
      <BlogHeader>
        <h1 className={styles.heading}>Frequently Asked Questions</h1>
      </BlogHeader>
      <FaqComponent />
    </BlogLayout>
  );
};

export default FAQs;
