import React, { useState } from "react";
import styles from "./BlogHeader.module.scss";
import MenuOverlay from "../menu-overlay/MenuOverlay";
import { MenuOverlayOptions } from "../../../enums/menu-overlay-options";
import cx from "classnames";
import AFGCHeader from "../afgc-header/AFGCHeader";
import { HeaderType } from "../../../enums/header-type.enum";

interface Props {
  children: React.ReactNode;
  selected?: MenuOverlayOptions;
  className?: string;
}

const BlogHeader: React.FC<Props> = (props: Props) => {
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  return (
    <React.Fragment>
      <AFGCHeader
        clicked={() => setIsPopUpOpen(true)}
        headerType={HeaderType.GREY}
        className={styles.header}
      />
      <div className={styles.blogHeader}>
        <div
          className={cx(
            "column",
            styles.blogHeader__content,
            props.className ?? ""
          )}
        >
          {props.children}
        </div>
        <MenuOverlay
          closePopUp={() => setIsPopUpOpen(false)}
          isOpen={isPopUpOpen}
          selected={props.selected}
        />
      </div>
    </React.Fragment>
  );
};

export default BlogHeader;
