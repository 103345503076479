import React from "react";
import FAQs1 from "../components/v1/pages/faqs";
import FAQs2 from "../components/v2/pages/faqs";

const FAQs = () => {
  if (process.env.GATSBY_VERSION === "v1") {
    return <FAQs1 />;
  } else {
    return <FAQs2 />;
  }
};

export default FAQs;
